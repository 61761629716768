.error-container {
  height: 100vh;
}

.error {
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -60%);
}

.buttons a {
  margin-right: 1rem;
}

.not-found {
  margin-top: 1rem;
  text-align: center;
}
