.loader {
  text-align: center;
}

.text {
  color: var(--gray-400);
}

.circle {
  display: block;
  width: 44px;
  height: 44px;
  margin: 0 auto;
  border: 4px solid var(--white);
  border-top: 4px solid var(--primary);
  border-right: 4px solid var(--primary);
  border-bottom: 4px solid var(--primary);
  border-radius: 50%;
  animation: spin 1s infinite linear;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(359deg);
  }
}
